
/********************************
Styles feuilles de print ***********/

@media print {

  footer {
    color: #000000;
  }

  body {
    font-size: 1.6rem;
  }

  h2.h3, h3.h4 {
    font-weight: bold;
  }
  h2.h3 {
    font-size: 2.2rem;
    line-height: 26px;
  }
  h3.h4 {
    font-size: 2rem;
    line-height: 24px;
  }

  .section-default,
  .section-big {
    padding: 0;
  }

  a[href]:after {
    content: none !important;
  }
  .btn,
  .section-tabs,
  .cd-main-content .animated-popup,
  .pagination,
  footer {
    display: none;
  }
  body:before {
    content: url($baseUrl + '/images/logo-primardeco.png') !important;
    display: block;
    text-align: center;
    margin: auto;
  }

  article .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }
}